
.portion-name {
  border-radius: 5px;
  border-color: lightblue;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  padding: 2px 8px;
}

.portion-menu { 
  opacity: 0;
  display: flex;
  align-items: center
}

.portion-portion:hover .portion-menu { 
  opacity: 1; 
  transition-delay: .5s;
  transition-duration: .5s;
}

.portion-add-button {
  color: lightgrey;
  font-size: 170%;
  margin: 0 16px;
}

.portion-add-button:hover {
  color: #337ab7;
}

.portion-handle {
  color: lightgrey;
  opacity: 0;
  font-size: 120%;
  margin: 0px 4px;
  cursor: grab;
}

.portion-portion:hover .portion-handle {
  opacity: 1; 
  transition-delay: .5s;
  transition-duration: .5s;
}

.portion-handle:hover {
  color: #337ab7;
}

.portion-show-commands {
  color: lightgrey;
}

.portion-show-commands:hover {
  color: #337ab7;
}

@media screen and (any-hover: none), (any-hover: on-demand) {
  .portion-show-commands {
    color: #337ab7;
  }
}

.portion-button {
  margin: 0 5px;
  color: #337ab7;
  font-size: 120%;
}

.portion-portion {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.portion-notifications {
  display: inline-block;
}

.portion-notifications i.fa-asterisk {
  font-size: 50%;
  vertical-align: text-top;
}

.portion-references {
  display: inline-block;
  margin-left: 5px;
}

.portion-error {
  color: red;
}

.portions-menu { 
  display: flex;
  align-items: center
}

.portions-menu-item {
    color: lightgrey;
    font-size: 120% !important;
}

.portions-menu-item:hover {
    color: #337ab7;
}

.portion-info-icon {
  font-size: 140%;
  padding-right: 28px;
  color: #337ab7;
  vertical-align: -2px;
}