.portion-selector {
    margin-bottom: 10px;
    font-size: 125%;
    width: 100%;
    height: 30px;
    background-color: white;
}

.passage-list-item {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    width: 100%;
}

.passage-info-text {
    margin-top: 5px;
    color: grey;
}

.passage-box {
    display: flex;
    flex-direction: column;
    min-width: 0;
    width: 100%;
}

.passage-box input {
    margin: 0px 16px;
    padding: 2px 8px;
}

.passage-button {
    white-space: nowrap;
}

.passage-menu {
    display: flex;
    align-items: center;
}

.passage-menu-button {
    margin-right: 5px;
    color: lightgrey;
}

body[dir='rtl'] .passage-menu-button {
    margin-right: 0;
    margin-left: 5px;
}

.passage-menu-button.fa-copy {
    font-size: 100%;
}

.passage-menu-item {
    font-size: 120%;
}

.passage-approval-state {
    margin-right: 7px;
}

body[dir='rtl'] .passage-approval-state {
    margin-right: 0;
    margin-left: 7px;
}

.passage-menu-button:hover {
    color: #337ab7;
}

.passage-show-commands {
    color: lightgrey;
}

.passage-show-commands:hover {
    color: #337ab7;
}

.passage-handle {
    color: #337ab7;
    font-size: 120%;
    margin: 0px 4px;
    cursor: grab;
}

.passages {
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 10px;
}

.passage-list-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.passage-list-buttons {
    padding: 0 16px;
    display: flex;
    gap: 5px;
}

.passage-list-button {
    color: lightgrey;
    font-size: 170%;
}

.passage-list-button:hover {
    color: #337ab7;
}

@media screen and (max-width: 600px) {
    .passages {
        height: auto;
    }
}

.btn.passage-button:focus {
    outline: 1px solid transparent; /* prevent bootstrap from giving this an outline when focused */
}

.btn.passage-button {
    font-size: 100%;
    color: #337ab7 !important;
    background-color: #fff;
    padding: 3px 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.btn.passage-video-present {
    font-size: 112%;
    font-weight: bold;
}

.btn.passage-selected {
    background-color: rgb(220, 231, 235);
}

.passage-assigned {
    border-left: 5px solid #337ab7;
}

body[dir='rtl'] .passage-assigned {
    border-left: none;
    border-right: 5px solid #337ab7;
}

.passage-notifications {
    display: inline-block;
    margin-right: 5px;
}

body[dir='rtl'] .passage-notifications {
    margin-right: 0;
    margin-left: 5px;
}

.passage-notifications i.fa-asterisk {
    font-size: 50%;
    vertical-align: text-top;
}

.passage-name {
    display: flex;
    align-items: center;
}

.passage-upload-status {
    margin-left: 5px;
    color: grey;
    font-size: 90%;
}

body[dir='rtl'] .passage-upload-status {
    margin-left: 0;
    margin-right: 5px;
}

.passage-error {
    color: red;
}

.error-text {
    color: red;
}

.passage-segment-approval-icon {
    margin-left: 5px;
    font-size: 120%;
}

body[dir='rtl'] .passage-segment-approval-icon {
    margin-left: 0;
    margin-right: 5px;
}

.passage-document-add-button {
    color: #337ab7;
}

.large-passage-document-button {
    font-size: 200%;
}

.small-passage-document-button {
    font-size: 130%;
}

.passage-document-edit-title-button {
    color: #337ab7;
}

.passage-document-edit-document-button {
    color: #337ab7;
}

.passage-document-edit-button {
    margin-left: 5px;
}

body[dir='rtl'] .passage-document-edit-button {
    margin-left: 0;
    margin-right: 5px;
}

.passage-document-toolbar {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.passage-document-toolbar-push-right {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
}

.passage-document-text {
    display: flex;
    flex-grow: 1;
    min-height: 0;
}

.passage-document-audio {
    margin-bottom: 10px;
}

.passage-document-button {
    font-size: 130%;
    margin-right: 5px;
}

body[dir='rtl'] .passage-document-button {
    margin-right: 0;
    margin-left: 5px;
}

.passage-document-trash-button {
    margin-left: auto;
}

body[dir='rtl'] .passage-document-trash-button {
    margin-left: 0;
    margin-right: auto;
}

.passage-document__outer-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.passage-document__inner-wrapper {
    flex-basis: 100%;
    flex-grow: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.rich-text-viewer {
    flex-basis: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.passage-document__editing-area {
    min-height: 0;
    flex-basis: 100%;
}

.passage-document__editing-area__editing {
    min-height: 0;
    flex-basis: 100%;
}

.ql-container {
    font-size: 110% !important;
}

.tooltip-inner {
    text-align: left !important;
}

.passage-card-drag-handle {
    font-size: 1em;
    padding: 0 3px;
}

.passage-document-global-icon {
    font-size: 1em;
    padding: 0 5px;
}

.passage-references-icon {
    font-size: 140%;
    padding-right: 28px;
    color: #337ab7;
    vertical-align: -2px;
}

body[dir='rtl'] .passage-references-icon {
    padding-right: 0;
    padding-left: 28px;
}

.passage-difficulty-icon {
    font-size: 140%;
    color: #337ab7;
    padding-right: 28px;
    vertical-align: -2px;
}

body[dir='rtl'] .passage-difficulty-icon {
    padding-right: 0;
    padding-left: 28px;
}

.text-input-wrapper input {
    width: 250px;
}

.oral-passage-document-title {
    margin-bottom: 10px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.passage-document-selector {
    margin-right: 5px;
}

body[dir='rtl'] .passage-document-selector {
    margin-right: 0;
    margin-left: 5px;
}

.passage-document-viewer {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex-grow: 1;
    min-height: 0;
}

.passage-document-viewer-top {
    display: flex;
    align-items: center;
}

.passage-document-separator {
    border-bottom: 1px solid lightgray;
    margin-top: 10px;
    margin-bottom: 10px;
}

.passage-document-toolbar-button {
    margin-left: 5px;
}

body[dir='rtl'] .passage-document-toolbar-button {
    margin-left: 0;
    margin-right: 5px;
}

.passage-document-import-button {
    color: #337ab7;
}

.translation-right-pane:hover .opaque-on-hover {
    opacity: 1;
    transition-duration: 1s;
}

.passage-content-type-input {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.passages-modal-toggle {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    font-weight: 500;
}

.passage-resource-version-history {
    display: flex;
    align-self: flex-end;
    margin-bottom: 10px;
}

.add-verse-reference-label {
    font-weight: 500;
    margin-bottom: 10px;
}

.passage-document-timestamp {
    font-size: 90%;
    display: flex;
    justify-content: flex-end;
    padding-top: 5px;
}

.passage-recording-type-chooser-form {
    padding-left: 20px;
    padding-right: 20px;
}

.passage-recording-type-chooser-option {
    display: flex;
    gap: 4px;
    padding: 1px;
}

.passage-recording-type-headset-icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: middle;
}

body[dir='rtl'] .passage-recording-type-headset-icon {
    margin-right: -20px;
}

.passage-recording-type-video-icon {
    font-size: 105%;
    margin-left: -3px;
    margin-top: -1px;
}

body[dir='rtl'] .passage-recording-type-video-icon {
    margin-right: -20px;
}

.small-verse-reference-icon {
    height: 15px;
    width: 15px;
}

.passage-modal-verse-reference-container {
    display: flex;
    align-items: center;
    gap: 5px;
}
