.wavesurfer-audio-player {
    border: 1px solid lightgrey;
    flex-grow: 1;
}

/* current position cursor */
wave wave {
    z-index: 100 !important;
    left: 3px !important;
}

region[data-region-segment] {
    border-left: 4px solid lightblue;
}

region[data-region-patched='true'] {
    backdrop-filter: hue-rotate(145deg);
}

region[data-region-type='selection'] {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

/* selection drag handles */
region[data-region-type='selection'] handle {
    width: 20px !important;
    background-color: transparent !important;
}

region.wavesurfer-region:before {
    content: attr(data-region-label);
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    color: purple;
    margin-left: -7px;
    background-color: white;
    bottom: 0;
}

.wavesurfer-marker {
    position: 'relative';
    padding: 0;
    z-index: 10;
}

.wavesurfer-marker {
    height: 50% !important;
}

.wavesurfer-marker[style*='flex-direction: column;'] {
    top: 50% !important;
}

.wavesurfer-marker .note-icon {
    font-size: 15px;
    padding: 2px;
}

.wavesurfer-marker .key-term-marker-icon {
    font-size: 15px;
    padding: 0px;
    color: darkorange;
}

.wavesurfer-marker .key-term-marker-icon.old {
    color: black;
}

.wavesurfer-marker .approval-icon {
    font-size: 20px;
    width: 28px;
}

.wavesurfer-marker .approval-icon-wrapper {
    position: relative;
    top: 32px;
    left: 2px;
}

.wavesurfer-marker .verse-icon {
    width: 15px;
}

.wavesurfer-marker .verse-marker {
    display: flex;
    align-items: baseline;
}

.wavesurfer-recent-note {
    border: 2px dotted gray;
}
