.progress-bar-custom {
    margin-bottom: 0px;
}

.progress-report-dropdown-menu {
    background-color: white;
    font-size: 100%;
}

.progress-report-header {
    margin-bottom: 10px;
}

.progress-report-controls {
    display: flex;
    gap: 10px;
}

.progress-report-separator {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 10px;
}

.progress-table-legend-item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

.progress-table-head {
    position: sticky;
    top: 0;
    background-color: lightgray;
}

.progress-table-head th {
    background-color: unset;
}

.progress-report-table {
    margin-bottom: 10px;
}

.progress-table-item {
    padding: 5px;
}

.progress-report-label {
    white-space: nowrap;
}

.progress-report-progress {
    min-width: 150px;
    width: 150px;
}

.csv-export-button {
    color: #337ab7;
    font-size: 120%;
    align-self: flex-end;
}
